import React from "react";
import Header from "./Components/Header";
import {BrowserRouter as Router} from "react-router-dom";
import AppRouter from "./Router";

function App() {

  return (
    <Router>
        <Header />
        <AppRouter />
    </Router>
  );
}

export default App;
